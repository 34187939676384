
/* eslint-disable @typescript-eslint/camelcase */
import { Options, prop, Vue } from "vue-class-component";
import SelectSearchV2 from "@/app/ui/components/select-search/index.vue";
import { RouteController } from "../../controllers/RouteController";
import { ClientController } from "../../controllers/ClientController";
import Checkbox from "primevue/checkbox";
import CustomDropdown from "@/app/ui/components/custom-dropdown/index.vue";
import { LocationController } from "../../controllers/LocationController";
import debounce from "lodash/debounce";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { CargoController } from "../../controllers/CargoController";
import { TopupApprovalController } from "../../controllers/TopupApprovalController";
import { TopUpVerificationController } from "../../controllers/TopUpVerificationController";
import { BookingCommissionController } from "@/app/ui/controllers/BookingCommissionControllers";
import { CommodityController } from "@/app/ui/controllers/CommodityController";
import { ShipmentPrefix } from "@/domain/entities/ShipmentBooking";
import RadioButton from "primevue/radiobutton";
import { EVENT_STATUS_OPTIONS } from "@/app/ui/views/messaging-service-configuration/MessagingServiceConfigurationUtils";
import { RequestListCommodity } from "@/data/payload/api/CommodityApiRequest";
import { PartnerController } from "../../controllers/PartnerController";
import SelectPhoneCountry from "@/app/ui/views/shipment/booking/component/select-phone-country.vue";
import { AccountController } from "../../controllers/AccountController";
import { ShipmentBookingController } from "@/app/ui/controllers/ShipmentBookingController";
import { OptionsClass } from "@/domain/entities/MainApp";

class Props {
  title = prop<string>({
    default: "Filter",
    type: String
  });
  showIconFilter = prop<boolean>({
    default: false,
    type: Boolean
  });
  isSeparateContent = prop<boolean>({
    default: false,
    type: Boolean
  });
  showCountFilter = prop<boolean>({
    default: true,
    type: Boolean
  })
  showChevron = prop<boolean>({
    default: true,
    type: Boolean
  });
  customTitleFilter = prop<string>({
    default: "",
    type: String
  });
  isCustomCheckbox = prop<boolean>({
    default: false,
    type: Boolean
  });
  titleCustomCheckbox = prop<string>({
    default: "",
    type: String
  });
  customCheckboxList = prop<any[]>({
    default: [],
    type: Array
  });
  isCustomDropdown = prop<boolean>({
    default: false,
    type: Boolean
  });
  titleCustomDropdown = prop<string>({
    default: "",
    type: String
  });
  customDropdownPlaceholder = prop<string>({
    default: "Pilih",
    type: String
  });
  customDropdownList = prop<any[]>({
    default: [],
    type: Array
  });
  isPeriode = prop<boolean>({
    default: false,
    type: Boolean
  });
  periodeTitle = prop<string>({
    default: "Booking",
    type: String
  });
  isOriginDestination = prop<boolean>({
    default: false,
    type: Boolean
  });
  isOrigin = prop<boolean>({
    default: true,
    type: Boolean
  });
  isDestination = prop<boolean>({
    default: true,
    type: Boolean
  });
  titleCityCustom = prop<string>({
    default: "Kecamatan, Kota Asal & Tujuan",
    type: String
  });
  placeholderOriginCustom = prop<string>({
    default: "Masukan kec. kota asal",
    type: String
  });
  isClientPartner = prop<boolean>({
    default: false,
    type: Boolean
  });
  isProduct = prop<boolean>({
    default: false,
    type: Boolean
  });
  isStatus = prop<boolean>({
    default: false,
    type: Boolean
  });
  isCOD = prop<boolean>({
    default: false,
    type: Boolean
  });
  isPAD = prop<boolean>({
    default: false,
    type: Boolean
  });
  isDFOD = prop<boolean>({
    default: false,
    type: Boolean
  });
  isAsuransi = prop<boolean>({
    default: false,
    type: Boolean
  });
  titleTypeCustom = prop<string>({
    default: "Type",
    type: String
  });
  isType = prop<boolean>({
    default: false,
    type: Boolean
  });
  isActivityCod = prop<boolean>({
    default: false,
    type: Boolean
  });
  isDisabled = prop<boolean>({
    default: false,
    type: Boolean
  });
  modelValue = prop<any>({
    required: true
  });
  addSumFilter = prop<number>({
    default: 0,
    type: Number
  });
  isCityOrigin = prop<boolean>({
    default: false,
    type: Boolean
  });
  isCityDestination = prop<boolean>({
    default: false,
    type: Boolean
  });
  isScroll = prop<boolean>({
    default: true,
    type: Boolean
  });
  isAirport = prop<boolean>({
    default: false,
    type: Boolean
  });
  customTypeList = prop<any[]>({
    default: [
      { name: "COD Earning", value: "cod_earning" },
      { name: "COD Fee", value: "cod_fee" }
    ],
    type: Array
  });
  bookingSourceList = prop<any[]>({
    default: [
      { name: "POS", value: "pos" },
      { name: "Client", value: "client" }
    ],
    type: Array
  });
  bookingSource = prop<boolean>({
    default: false,
    type: Boolean
  });
  appliedToList = prop<any[]>({
    default: [
      { name: "Booking Shipment", value: "shipment" },
      { name: "Booking Client", value: "for_client" },
      { name: "Booking Conventional", value: "manual" }
    ],
    type: Array
  });
  appliedTo = prop<boolean>({
    default: false,
    type: Boolean
  });
  shipmentPrefixList = prop<any[]>({
    default: [],
    type: Array
  });
  shipmentPrefix = prop<boolean>({
    default: false,
    type: Boolean
  });
  titleProductCustom = prop<string>({
    default: "Produk",
    type: String
  });
  customPlaceholder = prop<string>({
    default: "Pilih",
    type: String
  });
  commodityList = prop<any[]>({
    default: [],
    type: Array
  });
  commodity = prop<boolean>({
    default: false,
    type: Boolean
  });
  productBookingShipment = prop<boolean>({
    default: false,
    type: Boolean
  });
  clientPartnerTitle = prop<string>({
    default: "Booked For",
    type: String
  });
  clientPartnerPlaceholder = prop<string>({
    default: "Pilih client/partner",
    type: String
  });
  clientPartnerType = prop<string>({
    default: "",
    type: String
  });
  isCreatedBy = prop<boolean>({
    default: false,
    type: Boolean
  });
  isTopupVerification = prop<boolean>({
    default: false,
    type: Boolean
  });
  isTopupApproval = prop<boolean>({
    default: false,
    type: Boolean
  });
  customMaxHeight = prop<string>({
    default: "80vh",
    type: String
  });
  ableProductAll = prop<boolean>({
    default: false,
    type: Boolean
  });
  ableOriginAll = prop<boolean>({
    default: false,
    type: Boolean
  });
  ableDestinationAll = prop<boolean>({
    default: false,
    type: Boolean
  });
  ableCommodityAll = prop<boolean>({
    default: false,
    type: Boolean
  });
  isMessagingEventStatus = prop<boolean>({
    default: false,
    type: Boolean
  });
  isMessagingMessageStatus = prop<boolean>({
    default: false,
    type: Boolean
  });
  isManualDeductSaldo = prop<boolean>({
    default: false,
    type: Boolean
  });
  isChooseTypeAccount = prop<boolean>({
    default: false,
    type: Boolean
  });
  isStatusGoodTax = prop<boolean>({
    default: false,
    type: Boolean
  });
  isLastStatus = prop<boolean>({
    default: false,
    type: Boolean
  });
  isStatusPayment = prop<boolean>({
    default: false,
    type: Boolean
  });
  isDateRange = prop<boolean>({
    default: false,
    type: Boolean
  });
  titleDateRange = prop<string>({
    default: "Tanggal",
    type: String
  });
  defaultDateRange = prop<string>({
    default: "custom",
    type: String
  });
  isFetchConsolidator = prop<boolean>({
    default: false,
    type: Boolean
  });
  customTitlePlaceholderCity = prop<string>({
    default: "Kota Asal",
    type: String
  });
  customTitlePlaceholderAirportCode = prop<string>({
    default: "Kode Bandara Tujuan",
    type: String
  });
  customTitlePlaceholderCityDestination = prop<string>({
    default: "Kota Tujuan",
    type: String
  });
  isAddedStatus = prop<boolean>({
    default: false,
    type: Boolean
  });
  isPhoneNumber = prop<boolean>({
    default: false,
    type: Boolean
  });
  isPeriodeStartOnly = prop<boolean>({
    default: false,
    type: Boolean
  });
  isNewPeriodTitle = prop<boolean>({
    default: false,
    type: Boolean
  });
  withMaxMinDate = prop<boolean>({
    default: true,
    type: Boolean
  });
  isNestedStatus = prop<boolean>({
    default: false,
    required: false,
    type: Boolean
  });
  customSenderPhone = prop<string>({
    default: "",
    type: String
  });
  customSelectCityTitle = prop<string>({
    default: "",
    type: String
  });
  customSelectCityPlaceHolder = prop<string>({
    default: "",
    type: String
  });
  isButtonApply = prop<boolean>({
    default: false,
    type: Boolean
  });
  customWidth = prop<string>({
    default: "",
    type: String
  });
  disabledBackground = prop<boolean>({
    default: false,
    type: Boolean
  })
  showResetText = prop<boolean>({
    default: true,
    type: Boolean
  });
  customDropdownWidth = prop<string>({
    default: "",
    type: String
  });
  customPaddingVerticalHeader = prop<string>({
    default: 'py-2.5',
    type: String
  })
  isStatusReturn = prop<boolean>({
    default: false,
    type: Boolean
  })
  isCargoType = prop<boolean>({
    default: false,
    type: Boolean
  })
}
@Options({
  emits: ["opened", "change", "reset", "update:modelValue", "changeFilter", "resetFilter"],
  components: {
    SelectSearchV2,
    Checkbox,
    CustomDropdown,
    RadioButton,
    SelectPhoneCountry
  },
  directives: {
    "click-outside": {
      beforeMount: function(el, binding) {
        const ourClickEventHandler = (event: any) => {
          if (!el.contains(event.target) && el !== event.target) {
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;
        document.addEventListener("click", ourClickEventHandler);
      },
      unmounted: function(el) {
        document.removeEventListener("click", el.__vueClickEventHandler__);
      }
    }
  },
  watch: {
    openFilter() {
      if(!this.openFilter) {
        this.phoneSender = this.filterData.phoneSender;
        this.phoneReceiver = this.filterData.phoneReceiver;
      }
    }
  }
})
export default class Filter extends Vue.with(Props) {
  mounted() {
    this.filterProductsDelivery("");
    this.getInsurancesTypeDelivery();
    if (this.isTopupVerification || this.isTopupApproval) {
      ClientController._onGetListPartner({
        page: 1,
        limit: 50,
        search: "",
        partnerType: "pos"
      });
      this.fetchBankList();
      TopupApprovalController.getActorVerifiedList();
    }
    if (this.isManualDeductSaldo) {
      this.fetchActors("");
    }
    if (this.isFetchConsolidator) {
      this.filterConsolidatorByCity("");
    }
    this.filterData.phoneSender = this.customSenderPhone || ShipmentBookingController.advanceFilterData.phoneSender
    this.setPhoneSender(this.filterData.phoneSender)
  }
  isChevron = false;
  openFilter = false;
  resetSelectSearch = 0;
  resetfilter(value = true) {
    // reset date range
    const refs: any = this.$refs.dateRangeFilter;

    if (refs) {
      refs.dates = { start: null, end: null };
      refs.onDateSelected();
    }

    this.filterData = {
      periodeStart: "",
      periodeEnd: "",
      originCode: "",
      destinationCode: "",
      clientPartnerId: "",
      clientPartner: "",
      clientPartnerName: "",
      status: "",
      product: [],
      COD: false,
      PAD: false,
      DFOD: false,
      asuransi: [],
      cityOriginCode: "",
      cityDestinationCode: "",
      type: { name: "", value: "" },
      createdById: "",
      createdBy: { name: "", value: "" },
      airport: "",
      bookingSource: { name: "", value: "" },
      appliedTo: { name: "", value: "" },
      shipmentPrefix: "",
      commodity: "",
      partnerName: { name: "", value: "" },
      topupStatus: { name: "", value: "" },
      paymentStatus: { name: "", value: "" },
      startDateRange: "",
      endDateRange: "",
      bankName: { name: "", value: "" },
      verificationTopupBy: { name: "", value: "" },
      shipmentProduct: "",
      messagingEventStatus: { name: "", value: "" },
      messagingMessageStatus: { name: "", value: "" },
      statusGoodTax: "",
      customDropdown: { name: "", value: "" },
      customCheckbox: [],
      console: "",
      phoneReceiver: "",
      phoneSender: "",
      nestedStatus: [],
      districtId: ""
    };
    this.form = {
      origin: "",
      destination: "",
      client: "",
      status: "",
      cityOrigin: "",
      cityDestination: "",
      airport: "",
      commodity: "",
      shipmentPrefix: "",
      shipmentProduct: "",
      createdBy: "",
      statusGoodTax: ""
    };
    this.phoneReceiver = "";
    this.phoneCodeReceiver = "+62";
    this.phoneFlagReceiver = "indonesia";
    this.phoneSender = "";
    this.phoneCodeSender = this.accountIsNonForeign ? "+62" : "+60";
    this.phoneFlagSender = this.accountIsNonForeign ?  "indonesia" : "malaysia";
    this.resetSelectSearch++;
    this.$emit("reset", value);
    this.radioValue = "";
  }

  // filter data to be sent
  // important!! if modify this property, please update resetFilter() as well
  filterData: any = {
    periodeStart: "",
    periodeEnd: "",
    originCode: "",
    destinationCode: "",
    clientPartnerId: "",
    clientPartner: "",
    clientPartnerName: "",
    status: "",
    product: [],
    COD: false,
    PAD: false,
    asuransi: [],
    cityOriginCode: "",
    cityDestinationCode: "",
    type: { name: "", value: "" },
    createdById: "",
    createdBy: { name: "", value: 0 },
    airport: "",
    bookingSource: { name: "", value: "" },
    appliedTo: { name: "", value: "" },
    shipmentPrefix: "",
    commodity: "",
    partnerName: { name: "", value: "" },
    topupStatus: { name: "", value: "" },
    paymentStatus: { name: "Sudah Lunas", value: "paid" },
    startDateRange: "",
    endDateRange: "",
    bankName: { name: "", value: "" },
    verificationTopupBy: { name: "", value: "" },
    shipmentProduct: "",
    messagingEventStatus: { name: "", value: "" },
    messagingMessageStatus: { name: "", value: "" },
    statusGoodTax: "",
    customDropdown: { name: "", value: "" },
    customCheckbox: [],
    console: "",
    phoneReceiver: "",
    phoneSender: "",
    nestedStatus: [],
    districtId: ""
  };

  // form for select-search
  // important!! if modify this property, please update resetFilter() as well
  form: any = {
    origin: "",
    destination: "",
    client: "",
    status: "",
    cityOrigin: "",
    cityDestination: "",
    airport: "",
    commodity: "",
    shipmentPrefix: "",
    shipmentProduct: "",
    createdBy: "",
    statusGoodTax: ""
  };

  // nested status filted options
  nestedStatusOptions = [
    {
      name: "Status ongoing",
      isExpand: false,
      children: [
        {
          name: "BKD",
          value: "BKD"
        },
        {
          name: "PUP",
          value: "PUP"
        },
        {
          name: "STI-SC",
          value: "STI-SC"
        },
        {
          name: "STI",
          value: "STI"
        },
        {
          name: "BAGGING",
          value: "BAGGING"
        },
        {
          name: "CARGO PLANE",
          value: "CARGO PLANE"
        },
        {
          name: "CARGO TRUCK",
          value: "CARGO TRUCK"
        },
        {
          name: "CARGO TRAIN",
          value: "CARGO TRAIN"
        },
        {
          name: "CARGO SHIP",
          value: "CARGO SHIP"
        },
        {
          name: "PICKUP TRUCK",
          value: "PICKUP TRUCK"
        },
        {
          name: "DROPOFF TRUCK",
          value: "DROPOFF TRUCK"
        },
        {
          name: "TRANSIT",
          value: "TRANSIT"
        },
        {
          name: "STI-DEST",
          value: "STI-DEST"
        },
        {
          name: "HND",
          value: "HND"
        },
        {
          name: "DEL",
          value: "DEL"
        },
        {
          name: "STT REMOVE",
          value: "STT REMOVE"
        },
        {
          name: "STT ADJUSTED",
          value: "STT ADJUSTED"
        },
        {
          name: "HAL",
          value: "HAL"
        },
        {
          name: "ODA",
          value: "ODA"
        },
        {
          name: "OCC",
          value: "OCC"
        },
        {
          name: "STO-SC",
          value: "STO-SC"
        },
        {
          name: "OCC-HAL",
          value: "OCC-HAL"
        },
        {
          name: "OCC-EXP",
          value: "OCC-EXP"
        },
        {
          name: "OCC-IMP",
          value: "OCC-IMP"
        },
        {
          name: "INTHND",
          value: "INTHND"
        },
        {
          name: "INT-STI",
          value: "INT-STI"
        },
        {
          name: "IN HUB",
          value: "IN-HUB"
        },
        {
          name: "OUT HUB",
          value: "OUT-HUB"
        },
      ],
    },
    {
      name: "Status bermasalah",
      isExpand: false,
      children: [
        {
          name: "DEX",
          value: "DEX"
        }, 
        {
          name: "SCRAP",
          value: "SCRAP"
        }, 
        {
          name: "SHORTLAND",
          value: "SHORTLAND"
        }, 
        {
          name: "MIS-ROUTE",
          value: "MIS-ROUTE"
        }, 
        {
          name: "CODREJ",
          value: "CODREJ"
        }, 
        {
          name: "MISSING",
          value: "MISSING"
        }, 
        {
          name: "DAMAGE",
          value: "DAMAGE"
        }, 
        {
          name: "NOT RECEIVED",
          value: "NOT RECEIVED"
        }, 
        {
          name: "MISBOOKING",
          value: "MISBOOKING"
        },
        {
          name: "REJECTED",
          value: "REJECTED"
        },
      ],
    },
    {
      name: "Status final",
      isExpand: false,
      children: [
        {
          name: "POD",
          value: "POD",
        },
        {
          name: "CNX",
          value: "CNX",
        },
        {
          name: "RTS",
          value: "RTS",
        },
        {
          name: "CLAIM",
          value: "CLAIM",
        },
        {
          name: "CI",
          value: "CI",
        },
        {
          name: "RTSHQ",
          value: "RTSHQ",
        },
        {
          name: "STT ADJUSTED POD",
          value: "STT ADJUSTED POD",
        },
        {
          name: "REROUTE",
          value: "REROUTE",
        },
        {
          name: "CNXCD",
          value: "CNXCD",
        },
        {
          name: "HALCD",
          value: "HALCD",
        },
        {
          name: "SCRAPCD",
          value: "SCRAPCD",
        },
      ],
    },
  ]

  // Origin destination
  fetchOriginDestination = (search: string, type: string) => {
    search = search.split("-")[0];
    if (this.isOriginDestination) {
      if (search.length === 0) {
        if (type === "origin") {
          this.filterData.originCode = "";
          this.form.origin = "";
        } else if (type === "destination") {
          this.filterData.destinationCode = "";
          this.form.destination = "";
        }
        RouteController.getLocationSearchList({
          type: type,
          page: 1,
          limit: 10,
          search: "jaka"
        });
      } else if (search.length > 2) {
        RouteController.getLocationSearchList({
          type: type,
          page: 1,
          limit: 10,
          search: search
        });
      }
    }
  };
  get loadingOriginDestination() {
    return RouteController.isLoadingLocation;
  }

  get originList() {
    return RouteController.locationSearchOriginList.locationSearchData;
  }

  get destinationList() {
    return RouteController.locationSearchDestinationList.locationSearchData;
  }

  onChangeOrigin() {
    this.filterData.originCode = this.form.origin.code || "";
    this.filterData.districtId = this.form.origin.id || ""
    this.$emit("change", this.filterData);
    this.$emit("update:modelValue", this.filterData);
  }

  onChangeCommodity() {
    this.filterData.commodity = this.form.commodity.commodity_code || "";
    this.$emit("change", this.filterData);
    this.$emit("update:modelValue", this.filterData);
  }

  onChangeProduct() {
    this.filterData.shipmentProduct = this.form.shipmentProduct.value || "";
    this.$emit("change", this.filterData);
    this.$emit("update:modelValue", this.filterData);
  }

  applyFilter() {
    this.$emit("changeFilter", this.filterData);
  }

  resetToDefaultFilter() {
    this.resetfilter()
    ShipmentBookingController.setAdvanceFilterData({
      periodeStart: "",
      periodeEnd: "",
      originCode: "",
      destinationCode: "",
      clientPartnerId: "",
      status: "",
      product: [],
      COD: false,
      PAD: false,
      DFOD: false,
      asuransi: [],
      nestedStatus: []
    });
    this.$emit("resetFilter");
  }

  // client
  fetchClientPartner(search: string) {
    if (this.isClientPartner && !this.isChooseTypeAccount) {
      if (search.length === 0) {
        this.filterData.clientPartner = "";
        this.form.client = "";
        ClientController._onGetListPartner({
          page: 1,
          limit: 50,
          search: search,
          partnerType: this.clientPartnerType
        });
      } else if (search.length > 2) {
        ClientController._onGetListPartner({
          page: 1,
          limit: 50,
          search: search,
          partnerType: this.clientPartnerType
        });
      }
    } else {
      if (search.length === 0) {
        this.filterData.clientPartner = "";
        this.form.client = "";
        ClientController._onGetListPartner({
          page: 1,
          limit: 50,
          search: search,
          partnerType: this.clientPartnerType,
          type: this.radioValue,
          isParent: true
        });
      } else if (search.length > 2) {
        ClientController._onGetListPartner({
          page: 1,
          limit: 50,
          search: search,
          partnerType: this.clientPartnerType,
          type: this.radioValue,
          isParent: true
        });
      }
    }
  }

  get loadingClientPartner() {
    return ClientController.isLoading;
  }

  get clientPartnerData() {
    return ClientController.clientPartnerData;
  }

  onChangeClient() {
    this.filterData.clientPartner = this.form.client.code;
    this.filterData.clientPartnerId = this.form.client.id;
    this.filterData.clientPartnerName = this.form.client.name;
    this.$emit("change", this.filterData);
    this.$emit("update:modelValue", this.filterData);
  }

  // city origin
  fetchCityOrigin(search: string) {
    search = search || "";
    search = search.split(" - ").length > 1 ? search.split(" - ")[1] : search;
    if (this.isCityOrigin) {
      if (search.length === 0) {
        this.filterData.cityOriginCode = "";
        this.form.cityOrigin = "";
        LocationController.getCityList({
          page: 1,
          limit: 10,
          search: search,
          status: ""
        });
      } else if (search.length > 2) {
        LocationController.getCityList({
          page: 1,
          limit: 10,
          search: search,
          status: ""
        });
      }
    }
  }
  get loadingCityOrigin() {
    return LocationController.isLoading;
  }
  get cityOriginData() {
    const listData = LocationController.cityData.cityData
      .filter(item => item.name !== "")
      .map(item => {
        return { ...item, name: `${item.code} - ${item.name}` };
      });

    return this.ableOriginAll
      ? [{ id: 0, name: "All", code: "" }, ...listData]
      : listData;
  }
  async onChangeCityOrigin() {
    this.filterData.cityOriginCode = this.form.cityOrigin.code || "";

    if (this.isFetchConsolidator) {
      this.filterData.console = "";
      PartnerController.setLoadingConsolidatorsByCity(true);
      const isSuccess = await this.filterConsolidatorByCity("");

      if (isSuccess && this.form.cityOrigin.code) {
        this.filterData.console = this.consolidatorData.length
          ? this.consolidatorData[0]
          : "";
      }
    }
    this.onChange();
  }

  // city destination
  fetchCityDestination(search: string) {
    search = search.split(" - ").length > 1 ? search.split(" - ")[1] : search;
    if (this.isCityDestination) {
      if (search.length === 0) {
        this.filterData.cityDestinationCode = "";
        this.form.cityDestination = "";
        LocationController.getCityList({
          page: 1,
          limit: 10,
          search: search,
          status: ""
        });
      } else if (search.length > 2) {
        LocationController.getCityList({
          page: 1,
          limit: 10,
          search: search,
          status: ""
        });
      }
    }
  }
  get loadingCityDestination() {
    return LocationController.isLoading;
  }
  get cityDestinationData() {
    const listData = LocationController.cityData.cityData
      .filter(item => item.name !== "")
      .map(item => {
        return { ...item, name: `${item.code} - ${item.name}` };
      });

    return this.ableDestinationAll
      ? [{ id: 0, name: "All", code: "" }, ...listData]
      : listData;
  }
  onChangeCityDestination() {
    this.filterData.cityDestinationCode = this.form.cityDestination.code || "";
    this.$emit("change", this.filterData);
    this.$emit("update:modelValue", this.filterData);
  }

  //status
  statusOpened = false;
  selectedStatus = "";
  get statusOptions() {
    const statusFilter = [
      { name: "BKD", value: "bkd" },
      { name: "PUP", value: "pup" },
      { name: "STI-SC", value: "sti-sc" },
      { name: "STO-SC", value: "sto-sc" },
      { name: "STI", value: "sti" },
      { name: "BAGGING", value: "bagging" },
      { name: "ECARGO", value: "ecargo" },
      { name: "TRUCK", value: "truck" },
      { name: "TRANSIT", value: "transit" },
      { name: "STI-DEST", value: "sti-dest" },
      { name: "STO-DEST", value: "sto-dest" },
      { name: "STI DEST-SC", value: "sti dest-sc" },
      { name: "STO DEST-SC", value: "sto dest-sc" },
      { name: "DEL", value: "del" },
      { name: "POD", value: "pod" },
      { name: "STT ADJUSTED POD", value: "STT ADJUSTED POD" },
      { name: "DEX", value: "dex" },
      { name: "CNX", value: "cnx" },
      { name: "STT REMOVE", value: "stt remove" },
      { name: "SCRAP", value: "scrap" },
      { name: "SHORTLAND", value: "shortland" },
      { name: "MIS-ROUTE", value: "mis-route" },
      { name: "STT ADJUSTED", value: "stt adjusted" },
      { name: "HAL", value: "hal" },
      { name: "ODA", value: "oda" },
      { name: "CODREJ", value: "codrej" },
      { name: "SDEX", value: "sdex" },
      { name: "SPOD", value: "spod" },
      { name: "REJECTED", value: "rejected" },
      { name: "REROUTE", value: "reroute" },
      { name: "MISBOOKING", value: "misbooking" },
      { name: "RTS", value: "rts" },
      { name: "RTSHQ", value: "rtshq" },
      { name: "TFDREQ", value: "tfdreq" },
      { name: "DELTRF", value: "deltrf" },
      { name: "TFDCNC", value: "tfdcnc" },
      { name: "CNXCD", value: "cnxcd" },
      { name: "OCC-HAL", value: "occ-hal" },
      { name: "OCC-EXP", value: "occ-exp" },
      { name: "OCC-IMP", value: "occ-imp" },
      { name: "INTHND", value: "inthnd" },
      { name: "INT-STI", value: "int-sti" }
    ];

    if (this.isAddedStatus) {
      statusFilter.splice(1, 0, { name: "PUP-C", value: "pup-c" });
    }
    return statusFilter;
  }
  onSelectStatus(status: any) {
    if (this.filterData.status === status.value) {
      this.filterData.status = "";
    } else {
      this.filterData.status = status.value;
    }
    this.selectedStatus = status.name;
    this.statusOpened = false;
    this.onChange();
  }
  //product
  filterProductsDelivery = debounce((search: string) => {
    if (this.isProduct) {
      BookingController.getProductsDelivery({
        search: search,
        page: 1,
        limit: 99,
        status: "active"
      });
    }
  }, 250);

  get productOptions() {
    const listData = BookingController.productsDelivery.data.map((e: any) => ({
      id: e.id,
      name: e.name,
      code: e.name,
      status: e.status
    }));

    return this.ableProductAll
      ? [
          {
            id: 0,
            name: "ALL",
            code: "",
            status: ""
          },
          ...listData
        ]
      : listData;
  }

  //cod
  COD = false;

  //asuransi
  getInsurancesTypeDelivery = debounce(() => {
    if (this.isAsuransi) {
      BookingController.getInsuranceTypesDelivery({
        insuranceConfig: 2
      });
    }
  }, 250);
  get asuransiOptions() {
    return BookingController.allInsuranceTypeDelivery.data.map((e: any) => ({
      name: e.name.replace("Asuransi", ""),
      code: e.name.replace("Asuransi", "Insurance")
    }));
  }

  // custom checkbox
  get customCheckboxOptions() {
    return this.customCheckboxList;
  }

  get sumFilterCity() {
    const originCityCalc =
      this.form.cityOrigin?.name === "All" ? 1 : this.filterData.cityOriginCode;
    const destinationCityCalc =
      this.form.cityDestination?.name === "All"
        ? 1
        : this.filterData.cityDestinationCode;
    return (
      (this.filterData.originCode ? 1 : 0) +
      (this.filterData.destinationCode ? 1 : 0) +
      (originCityCalc ? 1 : 0) +
      (destinationCityCalc ? 1 : 0)
    );
  }
  get sumFilterDateAndCreatedBy() {
    return (
      (this.filterData.periodeStart ? 1 : 0) +
      (this.filterData.periodeEnd ? 1 : 0) +
      (this.filterData.startDateRange || this.filterData.endDateRange ? 1 : 0) +
      (this.filterData.createdBy.name ? 1 : 0) +
      (this.filterData.createdById ? 1 : 0) +
      (this.filterData.verificationTopupBy.name ? 1 : 0)
    );
  }
  get sumFilterBookingRelated() {
    const commodityCalc =
      this.form.commodity?.name === "All" ? 1 : this.filterData.commodity;
    const shipmentPrefixCalc =
      this.form.shipmentPrefix?.name === "All"
        ? 1
        : this.filterData.shipmentPrefix;
    const shipmentProductCalc =
      this.form.shipmentProduct?.name === "All"
        ? 1
        : this.filterData.shipmentProduct;
    return (
      (shipmentPrefixCalc ? 1 : 0) +
      (commodityCalc ? 1 : 0) +
      (shipmentProductCalc ? 1 : 0) +
      this.filterData.product.length +
      (this.filterData.bookingSource.name ? 1 : 0) +
      (this.filterData.appliedTo.name ? 1 : 0) +
      (this.filterData.COD.toString() == "true" ? 1 : 0) +
      (this.filterData.PAD ? 1 : 0) +
      this.filterData.asuransi.length +
      (this.filterData.status ? 1 : 0) +
      (this.filterData.statusGoodTax ? 1 : 0)
    );
  }
  get sumFilterBalanceRelated() {
    return (
      (this.filterData.partnerName.name ? 1 : 0) +
      (this.filterData.topupStatus.name ? 1 : 0) +
      (this.filterData.bankName.name ? 1 : 0) +
      (this.isStatusPayment && this.filterData.paymentStatus.name ? 1 : 0)
    );
  }
  get sumFilter() {
    // arrange same as filterdata
    return (
      this.sumFilterCity +
      this.sumFilterBalanceRelated +
      this.sumFilterBookingRelated +
      this.sumFilterDateAndCreatedBy +
      (this.filterData.clientPartner ? 1 : 0) +
      (this.filterData.type.name ? 1 : 0) +
      (this.filterData.airport ? 1 : 0) +
      (this.filterData.messagingEventStatus?.value ? 1 : 0) +
      (this.filterData.messagingMessageStatus?.value ? 1 : 0) +
      (this.filterData.customDropdown.name ? 1 : 0) +
      (this.filterData.console ? 1 : 0) +
      this.filterData.customCheckbox.length +
      (this.isPhoneNumber && this.filterData.phoneReceiver ? 1 : 0) +
      (this.isPhoneNumber && this.filterData.phoneSender ? 1 : 0)
    );
  }

  onChange() {
    this.$emit("change", this.filterData);
    this.$emit("update:modelValue", this.filterData);
  }

  get formOrigin() {
    let origin = "";
    if (this.form.origin["name"]) {
      origin = this.form.origin["name"];
    } else {
      origin = this.form.origin;
    }
    return origin;
  }

  get formDestination() {
    let destination = "";
    if (this.form.destination["name"]) {
      destination = this.form.destination["name"];
    } else {
      destination = this.form.destination;
    }
    return destination;
  }

  get formClient() {
    let client = "";
    if (this.form.client["name"]) {
      client = this.form.client["name"];
    } else {
      client = this.form.client;
    }
    return client;
  }

  get formCityDestination() {
    let city = "";
    if (this.form.cityDestination["name"]) {
      city = this.form.cityDestination["name"];
    } else {
      city = this.form.cityDestination;
    }
    return city;
  }

  get formCityOrigin() {
    let city = "";
    if (this.form.cityOrigin["name"]) {
      city = this.form.cityOrigin["name"];
    } else {
      city = this.form.cityOrigin;
    }
    return city;
  }

  // customDropdown
  customDropdownOpened = false;
  selectedcustomDropdown = "";
  get customDropdownOptions() {
    return this.customDropdownList;
  }
  onSelectCustomDropdown(data: any) {
    if (this.filterData.customDropdown.value === data.value) {
      this.filterData.customDropdown = { name: "", value: "" };
    } else {
      this.filterData.customDropdown = data;
    }
    this.selectedcustomDropdown = data.name;
    this.customDropdownOpened = false;
    this.onChange();
  }

  // type
  typeOpened = false;
  selectedType = "";
  get typeOptions() {
    return this.customTypeList;
  }
  onSelectType(type: any) {
    if (this.filterData.type.value === type.value) {
      this.filterData.type = { name: "", value: "" };
    } else {
      this.filterData.type = type;
    }
    this.selectedType = type.name;
    this.typeOpened = false;
    this.onChange();
  }

  // created by
  createdByOpened = false;
  selectedCreatedBy = "";

  onSelectCreatedBy() {
    this.filterData.createdById = this.form.createdBy.createdId;
    this.onChange();
  }

  createdByName = "";

  createdBySelect = false;

  onOpenCreatedBySelect() {
    this.createdBySelect = true;
  }

  onCloseCreatedBySelect() {
    this.createdBySelect = false;
  }

  fetchActors = debounce((search: string) => {
    return ClientController.getListOfActor(search);
  }, 250);

  get listActor() {
    return ClientController.actors;
  }

  get isLoadingActors() {
    return ClientController.isLoadingActor;
  }

  // airport code
  fetchAirport(search: string) {
    search = search || "";
    search = search.split(" - ").length > 1 ? search.split(" - ")[1] : search;
    const destination = this.form.cityDestination;
    CargoController.getAirportListDestination({
      airportCode: "",
      cityCode: destination ? destination.code : search,
      page: 1,
      limit: 10
    });
  }
  get isLoadingAirport() {
    return CargoController.isLoadingAirport;
  }
  get optionAirport() {
    return CargoController.airportDataDestination.data.map(airport => ({
      ...airport,
      airportName: `${airport.airportCode} - ${airport.airportName}`
    }));
  }
  onChangeAirport() {
    this.filterData.airport = this.form.airport.airportCode || "";
    this.$emit("change", this.filterData);
    this.$emit("update:modelValue", this.filterData);
  }

  statusGoodTaxOptions = [
    {
      name: "Pending",
      value: "pending"
    },
    {
      name: "Active",
      value: "active"
    },
    {
      name: "Expired",
      value: "inactive"
    }
  ];
  onChangeStatusGoodTaxValue() {
    this.filterData.statusGoodTax = this.form.statusGoodTax || "";
    this.$emit("change", this.filterData);
    this.$emit("update:modelValue", this.filterData);
  }

  // booking source
  bookingSourceOpened = false;
  selectedBookingSource = "";
  get bookingSourceOptions() {
    return this.bookingSourceList;
  }

  onSelectBookingSource(type: any) {
    if (this.filterData.bookingSource === type.value) {
      this.filterData.bookingSource = { name: "", value: "" };
    } else {
      this.filterData.bookingSource = type;
    }
    this.selectedBookingSource = type.name;
    this.bookingSourceOpened = false;
    this.onChange();
  }

  // applied to
  appliedToOpened = false;
  appliedToSource = "";
  get appliedToOptions() {
    return this.appliedToList;
  }

  onSelectAppliedTo(type: any) {
    if (this.filterData.appliedTo === type.value) {
      this.filterData.appliedTo = { name: "", value: "" };
    } else {
      this.filterData.appliedTo = type;
    }
    this.appliedToSource = type.name;
    this.appliedToOpened = false;
    this.onChange();
  }

  // shipment prefix
  shipmentIdOptions: ShipmentPrefix[] = [];
  async fetchShipmentId() {
    this.shipmentIdOptions = await BookingCommissionController.fetchListShipmentPrefix();
  }

  get shipmentPrefixOptions() {
    const listData = this.shipmentIdOptions.map((e: any) => {
      return {
        name: e.name,
        prefix: e.prefix
      };
    });

    return [{ name: "All", prefix: "" }, ...listData];
  }

  // commodity
  commodityValueSearch = "";
  fetchCommodity(search: string) {
    CommodityController.getCommodityList(
      new RequestListCommodity({
        search: search,
        commodityStatus: "active"
      })
    );
  }
  filterCommodity = debounce((search: string) => {
    this.commodityValueSearch = search;
    this.fetchCommodity(search);
  }, 250);
  get commodityOptions() {
    const data = CommodityController.commodities.data;
    if (this.commodityValueSearch) return data;
    return [
      {
        commodity_id: 0,
        commodity_name: "All",
        commodity_code: ""
      },
      ...data
    ];
  }
  get isLoadingCommodity() {
    return CommodityController.isLoading;
  }

  onChangeDestination() {
    this.filterData.destinationCode = this.form.destination.code || "";
    this.$emit("change", this.filterData);
    this.$emit("update:modelValue", this.filterData);
  }

  onChangeShipmentPrefix() {
    this.filterData.shipmentPrefix = this.form.shipmentPrefix.prefix || "";
    this.$emit("change", this.filterData);
    this.$emit("update:modelValue", this.filterData);
  }

  // partner name
  partnerNameOpened = false;
  selectedPartnerName = "";
  get partnerNameOptions() {
    return ClientController.clientPartnerData.map(e => ({
      name: e.name,
      value: e.code
    }));
  }
  onSelectPartnerName(items: any) {
    if (this.filterData.partnerName.value === items.value) {
      this.filterData.partnerName = { name: "", value: "" };
    } else {
      this.filterData.partnerName = items;
    }
    this.selectedPartnerName = items.name;
    this.partnerNameOpened = false;
    this.onChange();
  }

  // topup status
  topupStatusOpened = false;
  selectedTopupStatus = "";
  get topupStatusOptions() {
    return [
      { name: "Pending", value: "pending" },
      { name: "Verified", value: "verified" },
      { name: "Approved", value: "approved" },
      { name: "Verification Rejected", value: "verification_rejected" },
      { name: "Rejected", value: "rejected" }
    ];
  }
  onSelectTopupStatus(items: any) {
    if (this.filterData.topupStatus.value === items.value) {
      this.filterData.topupStatus = { name: "", value: "" };
    } else {
      this.filterData.topupStatus = items;
    }
    this.selectedTopupStatus = items.name;
    this.topupStatusOpened = false;
    this.onChange();
  }

  get topuspApprovalStatusOptions() {
    return [
      { name: "Verified", value: "verified" },
      { name: "Approved", value: "approved" },
      { name: "Rejected", value: "rejected" }
    ];
  }

  // payment status
  paymentStatusOpen = false;
  selectedPaymentStatus = "";

  onSelectPaymentStatus(items: any) {
    if (this.filterData.paymentStatus.value === items.value) {
      this.filterData.paymentStatus = { name: "", value: "" };
    } else {
      this.filterData.paymentStatus = items;
    }
    this.selectedPaymentStatus = items.name;
    this.paymentStatusOpen = false;
    if (
      this.isStatusPayment &&
      this.filterData.paymentStatus.value === "unpaid"
    ) {
      this.filterData.startDateRange = "";
      this.filterData.endDateRange = "";
    }
    this.onChange();
  }

  get paymentStatusOptions() {
    return [
      { name: "Kurang Bayar", value: "unpaid" },
      { name: "Sudah Lunas", value: "paid" }
    ];
  }

  //date range picker
  get isShowDateRange() {
    const paymentValue = this.filterData.paymentStatus.value === "paid";
    const isStatusPayment = this.isStatusPayment ? paymentValue : true;
    return this.isDateRange ? isStatusPayment : false;
  }
  setPeriode(value: Array<any>) {
    this.filterData.startDateRange = value[0];
    this.filterData.endDateRange = value[1];
    this.onChange();
  }

  // bank name
  bankNameOpened = false;
  selectedBankName = "";
  bankFromApi: any[] = [];
  async fetchBankList() {
    this.bankFromApi = await TopUpVerificationController.getListBank();
  }
  get bankNameOptions() {
    return this.bankFromApi.map((item: any) => {
      return {
        name: item,
        value: item
      };
    });
  }
  onSelectBankName(items: any) {
    if (this.filterData.bankName.value === items.value) {
      this.filterData.bankName = { name: "", value: "" };
    } else {
      this.filterData.bankName = items;
    }
    this.selectedBankName = items.name;
    this.bankNameOpened = false;
    this.onChange();
  }

  // verification topup by
  verificationTopupByOpened = false;
  selectedVerificationTopupBy = "";
  get verificationTopupByOptions() {
    return TopupApprovalController.actorVerifiedList.map(e => ({
      name: e.actorName,
      value: e.actorId
    }));
  }
  onSelectVerificationTopupBy(items: any) {
    if (this.filterData.verificationTopupBy.value === items.value) {
      this.filterData.verificationTopupBy = { name: "", value: "" };
    } else {
      this.filterData.verificationTopupBy = items;
    }
    this.selectedVerificationTopupBy = items.name;
    this.verificationTopupByOpened = false;
    this.onChange();
  }

  // product
  fetchProduct(search: string) {
    BookingController.getProductsDelivery({
      search: search,
      page: 1,
      limit: 99,
      status: "active"
    });
  }

  filterProduct = debounce((search: string) => {
    this.fetchProduct(search);
  }, 250);

  get shipmentProductOptions() {
    return [
      {
        id: 0,
        name: "All",
        value: ""
      },
      ...BookingController.productsDelivery.data.map((e: any) => ({
        id: e.id,
        name: e.name,
        value: e.name
      }))
    ];
  }
  get isLoadingProduct() {
    return BookingController.loadingProductsDelivery;
  }

  // messaging service configuration
  get messagingEventStatusOptions() {
    return EVENT_STATUS_OPTIONS;
  }

  onSelectMessagingEventStatus(items: any) {
    if (this.filterData.messagingEventStatus.value === items.value) {
      this.filterData.messagingEventStatus = { name: "", value: "" };
    } else {
      this.filterData.messagingEventStatus = items;
    }
    this.onChange();
  }
  messagingMessageStatusOptions = [
    { name: "Waiting", value: "waiting" },
    { name: "Active", value: "active" },
    { name: "Nearly Expired", value: "nearly-expired" },
    { name: "Expired", value: "expired" }
  ];
  onSelectMessagingMessageStatus(items: any) {
    if (this.filterData.messagingMessageStatus.value === items.value) {
      this.filterData.messagingMessageStatus = { name: "", value: "" };
    } else {
      this.filterData.messagingMessageStatus = items;
    }
    this.onChange();
  }
  //radio
  radioValue = "";
  onChangeRadioValue() {
    if (this.radioValue === "partner") {
      this.radioValue = "partner";
    }
    if (this.radioValue === "client") {
      this.radioValue = "client";
    }
  }

  get maxStartDatePeriode() {
    if (this.filterData.periodeStart === "" && this.filterData.periodeEnd) {
      return this.filterData.periodeEnd;
    }

    return this.$moment(this.filterData.periodeEnd).isSameOrAfter(
      this.filterData.periodeStart
    )
      ? this.filterData.periodeEnd
      : new Date();
  }

  get maxEndDatePeriode() {
    if (this.filterData.periodeStart === "") {
      return new Date();
    }
    return this.$moment(this.filterData.periodeStart).isAfter(
      this.filterData.periodeEnd
    )
      ? this.filterData.periodeStart
      : new Date();
  }

  // consol
  isOpenConsole = false;
  filterConsolidatorByCity(search: string) {
    return PartnerController.getConsolidatorsByCity({
      search: search,
      page: 1,
      limit: 10,
      cityCode: this.filterData.cityOriginCode,
      type: "console",
      status: ""
    });
  }
  get loadingConsoleData() {
    return PartnerController.loadingConsolidatorsByCity;
  }
  get consolidatorData() {
    return PartnerController.consolidatorsByCity.consolidatorByCityData.map(
      e => ({
        id: e.id,
        value: e.id,
        name: `${e.name}`
      })
    );
  }

  onChangeConsole(value: any) {
    this.filterData.console = value;
    this.onChange();
  }

  // phone receiver
  phoneReceiver = "";
  phoneCodeReceiver = "+62";
  phoneFlagReceiver = "indonesia";
  setPhoneReceiver(val: string) {
    this.phoneReceiver = val;
    if(!val && this.filterData.phoneReceiver) {
      this.filterData.phoneReceiver = val;
      this.$emit("change", this.filterData);
    }
  }

  setPhoneCodeReceiver(val: string) {
    this.phoneCodeReceiver = val;
  }

  setPhoneFlagReceiver(val: string) {
    this.phoneFlagReceiver = val;
  }

  onSearchPhoneReceiver() {
    this.filterData.phoneReceiver = `${this.phoneCodeReceiver.substring(1)}${
      this.phoneReceiver
    }`;
    this.$emit("change", this.filterData);
  }

  // phone sender
  phoneSender = "";
  phoneCodeSender = this.accountIsNonForeign ? "+62" : "+60";
  phoneFlagSender = this.accountIsNonForeign ? "indonesia" : "malaysia";
  setPhoneSender(val: string) {
    this.phoneSender = val;
    if(!val && this.filterData.phoneSender) {
      this.filterData.phoneSender = val;
      this.$emit("change", this.filterData);
    }
  }

  setPhoneCodeSender(val: string) {
    this.phoneCodeSender = val;
  }

  setPhoneFlagSender(val: string) {
    this.phoneFlagSender = val;
  }

  onSearchPhoneSender() {
    this.filterData.phoneSender = `${this.phoneCodeSender.substring(1)}${
      this.phoneSender
    }`;
    this.$emit("change", this.filterData);
  }

  get accountIsNonForeign() {
    return !AccountController.accountData.accountIsForeign;
  }

  isParentSelected(index: number) {
    const statuses = this.nestedStatusOptions[index].children.map((status) => status.value.toUpperCase())

    return !statuses.map(status => this.filterData.nestedStatus.includes(status)).includes(false)
  }

  selectParentStatus(index: number) {
    const statuses = this.nestedStatusOptions[index].children.map((status) => status.value.toUpperCase())

    if (this.isParentSelected(index)) {
      statuses.forEach((status) => {
        this.filterData.nestedStatus = this.filterData.nestedStatus.filter((stat:string) => stat.toUpperCase() !== status)
      })
    } else {
      this.filterData.nestedStatus = [
        ...this.filterData.nestedStatus,
        ...statuses
      ]
    }

    this.$emit("change", this.filterData)
  }

  selectChildNestedStatus(status: string): void {
    if (this.filterData.nestedStatus.includes(status)) {
      this.filterData.nestedStatus = this.filterData.nestedStatus.filter((stat: string) => stat.toUpperCase() !== status.toUpperCase());
    } else {
      this.filterData.nestedStatus = [
        ...this.filterData.nestedStatus,
        status
      ]
    }

    this.$emit("change", this.filterData);
  }
}
